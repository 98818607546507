import { combineReducers } from 'redux';
import {

    GET_EMPLOYER_PAY_CYCLE_LIST_LOADING,
    GET_EMPLOYER_PAY_CYCLE_LIST_SUCCESS,
    GET_EMPLOYER_PAY_CYCLE_LIST_ERROR,

    GET_PAY_PERIOD_LIST_LOADING,
    GET_PAY_PERIOD_LIST_SUCCESS,
    GET_PAY_PERIOD_LIST_ERROR,
    RESET_PAY_PERIOD_LIST

} from './actions'

const initialStateList = {
    loading: false, list: [], error: "" 
}


const payCycles = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_EMPLOYER_PAY_CYCLE_LIST_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_EMPLOYER_PAY_CYCLE_LIST_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_PAY_CYCLE_LIST_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const payPeriods = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_PAY_PERIOD_LIST_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_PAY_PERIOD_LIST_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_PAY_PERIOD_LIST_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        case RESET_PAY_PERIOD_LIST: {
            return {  loading: false, list: [], error: "" }
        }
        default:
            return state
    }
};

export default combineReducers({
    payCycles,
    payPeriods
});