export const stepsOrder = {
    company_details: 0,
    funding_config: 1,
    fee_and_limit_config: 2,
    payroll_config: 3,
    contact_person: 4,
}


export function isPreviousStepTrue(currentStep, steps) {
    if (!steps) return false
    const currentStepIndex = stepsOrder[currentStep];
    if (currentStepIndex === 0) {
        return true;
    }
    const previousStep = Object.keys(stepsOrder).find(step => stepsOrder[step] === currentStepIndex - 1);
    return previousStep ? steps[previousStep] === true : false;
}


export function findFirstFalseStepIndex(steps) {
    if (!steps) return 0
    for (const step in stepsOrder) {
        if (steps[step] !== true) {
            return stepsOrder[step];
        }
    }
    return 0;
}



export function isCurrentStepTrue(currentStepIndex, steps) {
    if (!steps) return false
    const currentStep = Object.keys(stepsOrder).find(step => stepsOrder[step] === currentStepIndex);
    return currentStep ? steps[currentStep] === true : false;
}