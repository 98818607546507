import { call, put, takeLatest } from 'redux-saga/effects';
import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';


function* forgotPassword({ body }) {
    yield put({ type: FORGOT_PASSWORD_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.auth.forgot_password(),
            serverless: true,
            body
        });
        yield put({ type: FORGOT_PASSWORD_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: FORGOT_PASSWORD_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchForgotPasswordSaga() {
    yield takeLatest(FORGOT_PASSWORD, forgotPassword)
}