

import { toQuery } from './formatString';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const apiConfig = {
    baseURL: BASE_URL,
    employers: {
        get_employers: ({ query }) => `adv/employers${toQuery(query)}`, // GET
        get_employer: ({ employerId }) => `adv/employers/${employerId}`, // GET
        create_employer: () => `adv/employers`, // POST 
        update_employer: ({ employerId, query }) => `adv/employers/${employerId}${toQuery(query)}`,
        register_employer: () => `adv/employers/users`, // POST

        // Update employer status
        enable_employer: ({ employerId }) => `adv/employers/${employerId}/enable`, // POST
        disable_employer: ({ employerId }) => `adv/employers/${employerId}/disable`, // POST
        // user
        create_employer_user: ({ employer_id }) => `adv/employers/users/invite?employer_id=${employer_id}`, // POST
        get_employer_users: (query) => `adv/employers/users${toQuery(query)}`, // GET // NEW
        get_employer_user: (employer_user_id) => `adv/employers/users/${employer_user_id}`, // GET
        get_employer_invitations_users: (query) => `adv/employers/invitations${toQuery(query)}`, // GET // NEW
        update_employer_user: (employer_user_id) => `adv/employers/users/${employer_user_id}`, // PUT
        enable_employer_user: ({ employer_user_id }) => `adv/employers/users/${employer_user_id}/enable`, // POST
        disable_employer_user: ({ employer_user_id }) => `adv/employers/users/${employer_user_id}/disable`, // POST
    },
    payroll: {
        get_data: ({ query }) => `adv/employers/payroll-data?employer_id=${query.employerId}&payroll_data_type=${query.payroll_data_type}&item_per_page=${query.item_per_page}&number_page=${query.number_page}`,
        upload_data: (query) => `adv/employers/payroll-data${toQuery(query)}`, // POST
        integrate_data: () => `adv/payroll`, // POST // TODO
        get_employer_deducation_per_period: ({ employerId, payPeriodId }) => `adv/payroll/${employerId}/deductions/${payPeriodId}`, // GET
        get_employer_deducation: ({ employerId }) => `adv/payroll/${employerId}/deductions`,
        get_deducations: (query) => `adv/deductions${toQuery(query)}`, // GET
        add_deduction: () => `adv/payroll`, // POST // :TODO
        pay_net_minus_deductions: () => `adv/payroll`, // POST // :TODO
        repay_deductions: () => `adv/payroll`, // POST // :TODO

        // new 
        get_advances: ({ query }) => `adv/advances${toQuery(query)}`,
        get_advance_by_id: ({ advanceId }) => `adv/advances/${advanceId}`


    },
    employees: {
        get_employee_employer: ({ employerId, employeeId }) => `adv/employers/${employerId}/employees/${employeeId}`, // GET
        get_employee_employer_balance: ({ employerId, employeeId }) => `adv/employees/${employerId}/balance/${employeeId}`,
        get_employees_by_employer: ({ query }) => `adv/employees${toQuery(query)}`, // GET
        get_employee: ({ employeeId }) => `adv/employees/${employeeId}`, // GET
        update_employee: ({ employeeId }) => `adv/employees/${employeeId}`, // PUT
        disable_employee: ({ employeeId }) => `adv/employee/${employeeId}/disable`, // POST
        enable_employee: ({ employeeId }) => `adv/employee/${employeeId}/enable`, // POST
    },
    business: {
        get_businesses: () => `adv/businesses`,
        get_business: ({ businessId }) => `adv/businesses/${businessId}`,
        // get_customer_businesses: () => ``,
    },
    accounts: {
        get_business_accounts: ({ businessId }) => `adv/businesses/${businessId}/account`
    },
    auth: {
        isSuperAdmin: () => `adv/super-admin`,
        signin: () => `adv/auth/login`,
        signout: () => `adv/auth/employee/signout`,
        forgot_password: () => `adv/auth/employee/forgot-password`,
        change_password: () => `adv/auth/employee/change-password`,
        confirm_password: () => `adv/auth/employee/confirm-password`,
    },

    invitation: {
        verify_token: () => `adv/employers/verify-invitation-token`,
        pre_verify_token: () => `adv/employers/pre-verify-invitation-token`,
    },
    payPeriod: {
        get_payperiods: ({ employerId }) => `adv/employers/pay-periods?employer_id=${employerId}`,
        create_payperiod: ({ employerId }) => `adv/employers/pay-periods?employer_id=${employerId}`,
        delete_payperiod: ({ employerId, payPeriodId }) => `adv/employers/pay-periods/${payPeriodId}?employer_id=${employerId}`,
    },

};
