import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PrivateLayout from '../layouts/private';
import PublicLayout from '../layouts/public';
import { isEmpty } from 'lodash'

class Navigation extends React.Component {

    render() {
        const { tokens } = this.props
        if (!isEmpty(tokens.data)) {
            return <PrivateLayout />
        }
        return (
            <PublicLayout />
        )
    }
}



const mapStateToProps = ({ router: { location: { pathname } }, auth: { tokens } }) => ({
    pathname,
    tokens
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation))