
export const GET_PAYPERIOD = "GET_PAYPERIOD"
export const GET_PAYPERIOD_LOADING = "GET_PAYPERIOD_LOADING"
export const GET_PAYPERIOD_SUCCESS = "GET_PAYPERIOD_SUCCESS"
export const GET_PAYPERIOD_ERROR = "GET_PAYPERIOD_ERROR"

export const CREATE_PAYPERIOD = "CREATE_PAYPERIOD"
export const CREATE_PAYPERIOD_LOADING = "CREATE_PAYPERIOD_LOADING"
export const CREATE_PAYPERIOD_SUCCESS = "CREATE_PAYPERIOD_SUCCESS"
export const CREATE_PAYPERIOD_ERROR = "CREATE_PAYPERIOD_ERROR"

export const DELETE_PAYPERIOD = "DELETE_PAYPERIOD"
export const DELETE_PAYPERIOD_LOADING = "DELETE_PAYPERIOD_LOADING"
export const DELETE_PAYPERIOD_SUCCESS = "DELETE_PAYPERIOD_SUCCESS"
export const DELETE_PAYPERIOD_ERROR = "DELETE_PAYPERIOD_ERROR"

export const getPayPeriod = (body) => ({ type: GET_PAYPERIOD, body })
export const createPayPeriod = (body) => ({ type: CREATE_PAYPERIOD, body })
export const deletePayPeriod = (body) => ({ type: DELETE_PAYPERIOD, body })