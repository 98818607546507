export const GET_EMPLOYERS_LIST = "GET_EMPLOYERS_LIST"
export const GET_EMPLOYERS_LIST_LOADING = "GET_EMPLOYERS_LIST_LOADING"
export const GET_EMPLOYERS_LIST_SUCCESS = "GET_EMPLOYERS_LIST_SUCCESS"
export const GET_EMPLOYERS_LIST_ERROR = "GET_EMPLOYERS_LIST_ERROR"

export const GET_PRESIGNED_URL = "GET_PRESIGNED_URL"
export const GET_PRESIGNED_URL_LOADING = "GET_PRESIGNED_URL_LOADING"
export const GET_PRESIGNED_URL_SUCCESS = "GET_PRESIGNED_URL_SUCCESS"
export const GET_PRESIGNED_URL_ERROR = "GET_PRESIGNED_URL_ERROR"

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const SET_SELECTED_EMPLOYER = 'SET_SELECTED_EMPLOYER'

export const SET_AUTH_CODE = 'SET_AUTH_CODE'

export const GET_EMPLOYER_CONFIGURATION = "GET_EMPLOYER_CONFIGURATION"
export const GET_EMPLOYER_CONFIGURATION_LOADING = "GET_EMPLOYER_CONFIGURATION_LOADING"
export const GET_EMPLOYER_CONFIGURATION_SUCCESS = "GET_EMPLOYER_CONFIGURATION_SUCCESS"
export const GET_EMPLOYER_CONFIGURATION_ERROR = "GET_EMPLOYER_CONFIGURATION_ERROR"

export const toggleSidebar = (collapsed) => ({ type: TOGGLE_SIDEBAR, data: collapsed })
export const setSelectedEmployer = (selectedEmployer) => ({ type: SET_SELECTED_EMPLOYER, data: selectedEmployer })
export const getEmployersList = (body) => ({ type: GET_EMPLOYERS_LIST, body })
export const getPresignedUrl = (body) => ({ type: GET_PRESIGNED_URL, body })
export const setAuthCode = (data) => ({ type: SET_AUTH_CODE, data })
export const getEmployerConfiguration = (body) => ({ type: GET_EMPLOYER_CONFIGURATION, body })
