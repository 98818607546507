import { combineReducers } from "redux";
import {
  GET_TRANSACTIONS_LOADING,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTION_TYPES_LOADING,
  GET_TRANSACTION_TYPES_SUCCESS,
  GET_TRANSACTION_TYPES_ERROR,
  GET_EXPORT_TRANSACTIONS_ERROR,
  GET_EXPORT_TRANSACTIONS_SUCCESS,
  GET_EXPORT_TRANSACTIONS_LOADING,
} from "./actions";

const initialStateObject = {
  loading: false,
  data: {},
  error: "",
};

const initialStateList = {
  loading: false,
  list: [],
  error: "",
};

const transactions = (state, action) => {
  if (typeof state === "undefined") {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_TRANSACTIONS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_TRANSACTIONS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_TRANSACTIONS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const exportTransactions = (state, action) => {
  if (typeof state === "undefined") {
    return initialStateObject;
  }
  switch (action.type) {
    case GET_EXPORT_TRANSACTIONS_LOADING: {
      return {
        loading: true,
        data: {},
        error: "",
      };
    }
    case GET_EXPORT_TRANSACTIONS_SUCCESS: {
      return {
        loading: false,
        data: action.data,
        error: "",
      };
    }
    case GET_EXPORT_TRANSACTIONS_ERROR: {
      return {
        loading: false,
        data: {},
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

const transactionTypes = (state, action) => {
  if (typeof state === "undefined") {
    return initialStateList;
  }
  switch (action.type) {
    case GET_TRANSACTION_TYPES_LOADING: {
      return {
        loading: true,
        list: [],
        error: "",
      };
    }
    case GET_TRANSACTION_TYPES_SUCCESS: {
      return {
        loading: false,
        list: action.data,
        error: "",
      };
    }
    case GET_TRANSACTION_TYPES_ERROR: {
      return {
        loading: false,
        list: [],
        error: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  transactions,
  exportTransactions,
  transactionTypes,
});
