import React from 'react'
import { Layout, Menu } from 'antd';
import '../../style.css'
import { Logo, LoginMin } from '../../../assets/images';
import { CustomImage } from '../..';
const { Sider } = Layout;

const Sidebar = ({ isSidebarCollapsed, navigateTo, path, menu, userDetails }) => {
    return (
        <Sider
            width={260}
            trigger={null}
            collapsible
            collapsed={isSidebarCollapsed}
            breakpoint="lg"
            className="container"
        >
            <div className="logo">
                <CustomImage
                    src={isSidebarCollapsed ? LoginMin : Logo}
                />
            </div>
            <Menu mode="inline" selectedKeys={[path]} onClick={({ item, key, keyPath, domEvent }) => navigateTo(key)}>
                {menu.map((item, index) => {

                    if (!item.roles) {
                        return <Menu.Item key={item.path} icon={item.icon} disabled={item.disabled}>
                            {item.label}
                        </Menu.Item>
                    } else if (item.roles.includes(userDetails["custom:role"])) {
                        return <Menu.Item key={item.path} icon={item.icon} disabled={item.disabled}>
                            {item.label}
                        </Menu.Item>
                    } else {
                        return null
                    }

                }
                )}
            </Menu>
        </Sider>
    )
}

export default Sidebar