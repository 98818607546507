export const GET_EMPLOYEE_BY_ID = "GET_EMPLOYEE_BY_ID"
export const GET_EMPLOYEE_BY_ID_LOADING = "GET_EMPLOYEE_BY_ID_LOADING"
export const GET_EMPLOYEE_BY_ID_SUCCESS = "GET_EMPLOYEE_BY_ID_SUCCESS"
export const GET_EMPLOYEE_BY_ID_ERROR = "GET_EMPLOYEE_BY_ID_ERROR"

export const GET_EMPLOYEE_PAYTIME_INFORMATION = "GET_EMPLOYEE_PAYTIME_INFORMATION"
export const GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING = "GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING"
export const GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS = "GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS"
export const GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR = "GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR"

export const UPDATE_EMPLOYEE_STATUS = "UPDATE_EMPLOYEE_STATUS"
export const UPDATE_EMPLOYEE_STATUS_LOADING = "UPDATE_EMPLOYEE_STATUS_LOADING"
export const UPDATE_EMPLOYEE_STATUS_SUCCESS = "UPDATE_EMPLOYEE_STATUS_SUCCESS"
export const UPDATE_EMPLOYEE_STATUS_ERROR = "UPDATE_EMPLOYEE_STATUS_ERROR"

export const getEmployeeById = (body) => ({ type: GET_EMPLOYEE_BY_ID, body })
export const getEmployeePaytimeInformation = (body) => ({ type: GET_EMPLOYEE_PAYTIME_INFORMATION, body })
export const updateEmployeeStatus = (body) => ({ type: UPDATE_EMPLOYEE_STATUS, body })