
export const INVITE = 'INVITE'
export const INVITE_LOADING = 'INVITE_LOADING'
export const INVITE_SUCCESS = 'INVITE_SUCCESS'
export const REFRESH_INVITE = 'REFRESH_INVITE'
export const INVITE_ERROR = 'INVITE_ERROR'


export const PRE_VERIFY_TOKEN = "PRE_VERIFY_TOKEN"
export const PRE_VERIFY_TOKEN_LOADING = "PRE_VERIFY_TOKEN_LOADING"
export const PRE_VERIFY_TOKEN_SUCCESS = "PRE_VERIFY_TOKEN_SUCCESS"
export const PRE_VERIFY_TOKEN_ERROR = "PRE_VERIFY_TOKEN_ERROR"

export const VERIFY_TOKEN = "VERIFY_TOKEN"
export const VERIFY_TOKEN_LOADING = "VERIFY_TOKEN_LOADING"
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS"
export const VERIFY_TOKEN_ERROR = "VERIFY_TOKEN_ERROR"

export const REGISTER_EMPLOYER = "REGISTER_EMPLOYER"
export const REGISTER_EMPLOYER_START = "REGISTER_EMPLOYER_START"
export const REGISTER_EMPLOYER_SUCCESS = "REGISTER_EMPLOYER_SUCCESS"
export const REGISTER_EMPLOYER_ERROR = "REGISTER_EMPLOYER_ERROR"
export const RESET_EMPLOYER_SUCCESS = "RESET_EMPLOYER_SUCCESS"




export const preVarifyToken = (body) => ({ type: PRE_VERIFY_TOKEN, body })
export const verifyToken = (body) => ({ type: VERIFY_TOKEN, body })
export const invalid = (errorMessage) => ({ type: VERIFY_TOKEN_ERROR, errorMessage })
export const registerEmployer = (body) => ({ type: REGISTER_EMPLOYER, body })
export const resetEmployerDetails = () => ({ type: RESET_EMPLOYER_SUCCESS })