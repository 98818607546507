
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_ADVANCE_BY_ID,
    GET_ADVANCE_BY_ID_LOADING,
    GET_ADVANCE_BY_ID_SUCCESS,
    GET_ADVANCE_BY_ID_ERROR
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';


function* getAdvanceById({ body }) {
    yield put({ type: GET_ADVANCE_BY_ID_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.payroll.get_advance_by_id({ advanceId: body.advanceId }),
        });

        yield put({
            type: GET_ADVANCE_BY_ID_SUCCESS, data
        });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_ADVANCE_BY_ID_ERROR, errorMessage: '' });
    }
}



/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeeDetailsSaga() {
    yield takeLatest(GET_ADVANCE_BY_ID, getAdvanceById)
}