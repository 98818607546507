
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    ADD_EMPLOYER,
    ADD_EMPLOYER_LOADING,
    ADD_EMPLOYER_SUCCESS,
    ADD_EMPLOYER_ERROR,

    ADD_EMPLOYER_V2,
    ADD_EMPLOYER_V2_LOADING,
    ADD_EMPLOYER_V2_SUCCESS,
    ADD_EMPLOYER_V2_ERROR,

    UPDATE_EMPLOYER,
    UPDATE_EMPLOYER_LOADING,
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_ERROR,

    GET_EMPLOYER_BY_ID,
    GET_EMPLOYER_BY_ID_LOADING,
    GET_EMPLOYER_BY_ID_SUCCESS,
    GET_EMPLOYER_BY_ID_ERROR,

    UPDATE_EMPLOYER_STATUS,
    UPDATE_EMPLOYER_STATUS_ERROR,
    UPDATE_EMPLOYER_STATUS_LOADING,
    UPDATE_EMPLOYER_STATUS_SUCCESS,

    GET_PRESIGNED_URL,
    GET_PRESIGNED_URL_ERROR,
    GET_PRESIGNED_URL_LOADING,
    GET_PRESIGNED_URL_SUCCESS,

    UPLOAD_FILE,
    UPLOAD_FILE_ERROR,
    UPLOAD_FILE_LOADING,
    UPLOAD_FILE_SUCCESS,

    READ_UPLOADED_FILE,
    READ_UPLOADED_FILE_ERROR,
    READ_UPLOADED_FILE_LOADING,
    READ_UPLOADED_LEGAL_FILE_SUCCESS,
    READ_UPLOADED_PAYROLL_FILE_SUCCESS,
    READ_UPLOADED_SAAS_FILE_SUCCESS,

    SET_CURRENT_STEP,

    GET_BUSINESSES,
    GET_BUSINESSES_SUCCESS,
    GET_BUSINESSES_ERROR,
    GET_BUSINESSES_LOADING
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';



function* addEmployer({ body }) {
    yield put({ type: ADD_EMPLOYER_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.employers.update_employer(),
            body
        });
        yield put({ type: ADD_EMPLOYER_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: ADD_EMPLOYER_ERROR, errorMessage: '' });
    }
}

function* addEmployerV2({ body, navigateTo }) {
    console.log("Add Employer v2 Start")
    console.log({ body, navigateTo })
    yield put({ type: ADD_EMPLOYER_V2_LOADING });
    try {

        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.employers.create_employer(),
            body
        });

        yield put({
            type: ADD_EMPLOYER_V2_SUCCESS, data
        });
        navigateTo(`/employer-details/${data.id}`)

    } catch (error) {
        errorhandling(error)
        yield put({ type: ADD_EMPLOYER_V2_ERROR, errorMessage: '' });
    }
}

function* updateEmployer({ body }) {

    yield put({ type: UPDATE_EMPLOYER_LOADING });
    try {
        const payload = {
            ...body.employerDetails
        }
        const query = {
            step_name: body.step
        }
        const { data } = yield call(api, {
            method: 'PUT',
            url: apiConfig.employers.update_employer({
                employerId: body.employerId,
                query
            }),
            body: payload
        });
        yield put({ type: UPDATE_EMPLOYER_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPDATE_EMPLOYER_ERROR, errorMessage: '' });
    }
}

function* getEmployerById({ body }) {
    yield put({ type: GET_EMPLOYER_BY_ID_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.employers.get_employer({ employerId: body.employerId }),
        });
        const { data: business } = yield call(api, {
            method: 'GET',
            url: apiConfig.business.get_business({ businessId: data?.companyDetails?.businessId }),
        });
        yield put({
            type: GET_EMPLOYER_BY_ID_SUCCESS, data: {
                ...data,
                business
            }
        });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_BY_ID_ERROR, errorMessage: '' });
    }
}

function* updateEmployeeStatus({ body }) {

    yield put({ type: UPDATE_EMPLOYER_STATUS_LOADING });
    try {

        const { active, employerId } = body
        const path = active === false ? apiConfig.employers.enable_employer({ employerId }) : apiConfig.employers.disable_employer({ employerId })

        const { data } = yield call(api, {
            method: 'POST',
            url: path,
        });

        yield put({ type: GET_EMPLOYER_BY_ID, body: { employerId: body.employerId } })
        yield put({ type: UPDATE_EMPLOYER_STATUS_SUCCESS, data: data });
        yield put({ type: SET_CURRENT_STEP, data: -1 })
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPDATE_EMPLOYER_STATUS_ERROR, errorMessage: '' });
    }
}

function* getPreSignedUrl({ body }) {
    yield put({ type: GET_PRESIGNED_URL_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: 'uploads/getsignedurl',
            body
        });
        yield put({ type: GET_PRESIGNED_URL_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PRESIGNED_URL_ERROR, errorMessage: '' });
    }
}

function* uploadFile({ body }) {
    yield put({ type: UPLOAD_FILE_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'PUT',
            url: body.url,
            body: body.file.originFileObj
        });
        yield put({ type: UPLOAD_FILE_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPLOAD_FILE_ERROR, errorMessage: '' });
    }
}

function* readUploadedFile({ body }) {
    const { docType } = body
    delete body.docType
    yield put({ type: READ_UPLOADED_FILE_LOADING, data: { docType } });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: 'uploads/getitem',
            body: body
        });
        if (docType === "legalDocs") {
            yield put({ type: READ_UPLOADED_LEGAL_FILE_SUCCESS, data });
        } else if (docType === "payrollBankDDAuthDocs") {
            yield put({ type: READ_UPLOADED_PAYROLL_FILE_SUCCESS, data });
        } else {
            yield put({ type: READ_UPLOADED_SAAS_FILE_SUCCESS, data });
        }
    } catch (error) {
        errorhandling(error)
        yield put({ type: READ_UPLOADED_FILE_ERROR, data: { docType } });
    }
}

function* getBusinesses({ body }) {
    yield put({ type: GET_BUSINESSES_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.business.get_businesses(),
        });
        yield put({ type: GET_BUSINESSES_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_BUSINESSES_ERROR, errorMessage: '' });
    }
}



/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerDetailsSaga() {
    yield takeLatest(ADD_EMPLOYER, addEmployer)
    yield takeLatest(ADD_EMPLOYER_V2, addEmployerV2)
    yield takeLatest(UPDATE_EMPLOYER, updateEmployer)
    yield takeLatest(GET_EMPLOYER_BY_ID, getEmployerById)
    yield takeLatest(UPDATE_EMPLOYER_STATUS, updateEmployeeStatus)
    yield takeLatest(GET_PRESIGNED_URL, getPreSignedUrl)
    yield takeLatest(UPLOAD_FILE, uploadFile)
    yield takeEvery(READ_UPLOADED_FILE, readUploadedFile)
    yield takeLatest(GET_BUSINESSES, getBusinesses)
}