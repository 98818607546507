export const SET_CURRENT_STEP = "SET_CURRENT_STEP"

export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS"
export const RESET_COMPANY_DETAILS = "RESET_COMPANY_DETAILS"

export const SET_CONTACT_PERSON_DETAILS = "SET_CONTACT_PERSON_DETAILS"
export const RESET_CONTACT_PERSON_DETAILS = "RESET_CONTACT_PERSON_DETAILS"

export const SET_AUTH_CONFIG = "SET_AUTH_CONFIG"
export const RESET_AUTH_CONFIG = "RESET_AUTH_CONFIG"

export const SET_ACCESS_FEE_CONFIG = "SET_ACCESS_FEE_CONFIG"
export const RESET_ACCESS_FEE_CONFIG = "RESET_ACCESS_FEE_CONFIG"

export const SET_ACCESS_LIMIT = "SET_ACCESS_LIMIT"
export const RESET_ACCESS_LIMIT = "RESET_ACCESS_LIMIT"

export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS"
export const RESET_SUBSCRIPTIONS = "RESET_SUBSCRIPTIONS"

export const SET_PAYMENT_CONFIG = "SET_PAYMENT_CONFIG"
export const RESET_PAYMENT_CONFIG = "RESET_PAYMENT_CONFIG"

export const SET_DIRECT_DEBIT_CARD_CONFIG = "SET_DIRECT_DEBIT_CARD_CONFIG"
export const RESET_DIRECT_DEBIT_CARD_CONFIG = "RESET_DIRECT_DEBIT_CARD_CONFIG"

export const ADD_EMPLOYER = "ADD_EMPLOYER"
export const ADD_EMPLOYER_LOADING = "ADD_EMPLOYER_LOADING"
export const ADD_EMPLOYER_SUCCESS = "ADD_EMPLOYER_SUCCESS"
export const ADD_EMPLOYER_ERROR = "ADD_EMPLOYER_ERROR"

export const ADD_EMPLOYER_V2 = "ADD_EMPLOYER_V2"
export const ADD_EMPLOYER_V2_LOADING = "ADD_EMPLOYER_V2_LOADING"
export const ADD_EMPLOYER_V2_SUCCESS = "ADD_EMPLOYER_V2_SUCCESS"
export const ADD_EMPLOYER_V2_ERROR = "ADD_EMPLOYER_V2_ERROR"

export const GET_EMPLOYER_BY_ID = "GET_EMPLOYER_BY_ID"
export const GET_EMPLOYER_BY_ID_LOADING = "GET_EMPLOYER_BY_ID_LOADING"
export const GET_EMPLOYER_BY_ID_SUCCESS = "GET_EMPLOYER_BY_ID_SUCCESS"
export const GET_EMPLOYER_BY_ID_ERROR = "GET_EMPLOYER_BY_ID_ERROR"

export const UPDATE_EMPLOYER = "UPDATE_EMPLOYER"
export const UPDATE_EMPLOYER_LOADING = "UPDATE_EMPLOYER_LOADING"
export const UPDATE_EMPLOYER_SUCCESS = "UPDATE_EMPLOYER_SUCCESS"
export const UPDATE_EMPLOYER_ERROR = "UPDATE_EMPLOYER_ERROR"

export const RESET_EMPLOYER = "RESET_EMPLOYER"

export const UPDATE_EMPLOYER_STATUS = "UPDATE_EMPLOYER_STATUS"
export const UPDATE_EMPLOYER_STATUS_LOADING = "UPDATE_EMPLOYER_STATUS_LOADING"
export const UPDATE_EMPLOYER_STATUS_SUCCESS = "UPDATE_EMPLOYER_STATUS_SUCCESS"
export const UPDATE_EMPLOYER_STATUS_ERROR = "UPDATE_EMPLOYER_STATUS_ERROR"

export const GET_PRESIGNED_URL = "GET_PRESIGNED_URL"
export const GET_PRESIGNED_URL_LOADING = "GET_PRESIGNED_URL_LOADING"
export const GET_PRESIGNED_URL_SUCCESS = "GET_PRESIGNED_URL_SUCCESS"
export const GET_PRESIGNED_URL_ERROR = "GET_PRESIGNED_URL_ERROR"

export const UPLOAD_FILE = "UPLOAD_FILE"
export const UPLOAD_FILE_LOADING = "UPLOAD_FILE_LOADING"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"

export const READ_UPLOADED_FILE = "READ_UPLOADED_FILE"
export const READ_UPLOADED_FILE_LOADING = "READ_UPLOADED_FILE_LOADING"
export const READ_UPLOADED_LEGAL_FILE_SUCCESS = "READ_UPLOADED_LEGAL_FILE_SUCCESS"
export const READ_UPLOADED_PAYROLL_FILE_SUCCESS = "READ_UPLOADED_PAYROLL_FILE_SUCCESS"
export const READ_UPLOADED_SAAS_FILE_SUCCESS = "READ_UPLOADED_SAAS_FILE_SUCCESS"
export const READ_UPLOADED_FILE_ERROR = "READ_UPLOADED_FILE_ERROR"

// Business
export const GET_BUSINESSES = "GET_BUSINESSES"
export const GET_BUSINESSES_SUCCESS = "GET_BUSINESSES_SUCCESS"
export const GET_BUSINESSES_ERROR = "GET_BUSINESSES_ERROR"
export const GET_BUSINESSES_LOADING = "GET_BUSINESSES_LOADING"

export const setCurrentStep = (step) => ({ type: SET_CURRENT_STEP, data: step })

export const setCompanyDetails = (data) => ({ type: SET_COMPANY_DETAILS, data })
export const resetCompanyDetails = () => ({ type: RESET_COMPANY_DETAILS })

export const setContactPersonDetails = (data) => ({ type: SET_CONTACT_PERSON_DETAILS, data })
export const resetContactPersonDetails = () => ({ type: RESET_CONTACT_PERSON_DETAILS })

export const setPayrollAuthConfig = (data) => ({ type: SET_AUTH_CONFIG, data })
export const resetAuthConfig = () => ({ type: RESET_AUTH_CONFIG })

export const setAccessFeeConfig = (data) => ({ type: SET_ACCESS_FEE_CONFIG, data })
export const resetAccessFeeConfig = () => ({ type: RESET_ACCESS_FEE_CONFIG })

export const setAccessLimit = (data) => ({ type: SET_ACCESS_LIMIT, data })
export const resetAccessLimit = () => ({ type: RESET_ACCESS_LIMIT })

export const setSubscriptions = (data) => ({ type: SET_SUBSCRIPTIONS, data })
export const resetSubscriptions = () => ({ type: RESET_SUBSCRIPTIONS })

export const setPaymentConfig = (data) => ({ type: SET_PAYMENT_CONFIG, data })
export const resetPaymentConfig = () => ({ type: RESET_PAYMENT_CONFIG })

export const setDirectDebitAccountConfig = (data) => ({ type: SET_DIRECT_DEBIT_CARD_CONFIG, data })
export const resetDirectDebitAccountConfig = () => ({ type: RESET_DIRECT_DEBIT_CARD_CONFIG })

export const addEmployer = (body) => ({ type: ADD_EMPLOYER, body })

export const addEmployerV2 = (body, navigateTo) => ({ type: ADD_EMPLOYER_V2, body, navigateTo })

export const updateEmployer = (body) => ({ type: UPDATE_EMPLOYER, body })

export const getEmployerById = (body) => ({ type: GET_EMPLOYER_BY_ID, body })

export const updateEmployerStatus = (body) => ({ type: UPDATE_EMPLOYER_STATUS, body })

export const getPreSignedUrl = (body) => ({ type: GET_PRESIGNED_URL, body })

export const uploadFile = (body) => ({ type: UPLOAD_FILE, body })

export const readUploadedFile = (body) => ({ type: READ_UPLOADED_FILE, body })

export const resetEmployer = () => ({ type: RESET_EMPLOYER })
// Business
export const getBusinesses = (body) => ({ type: GET_BUSINESSES, body })

