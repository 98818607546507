import React from 'react';
import { Select } from 'antd';
import { isObject } from 'lodash'

const { Option } = Select;

const CustomSelect = ({ onChange, placeholder, options, value, disabled, mode }) => {
    return <Select
        size={"large"}
        value={value}
        disabled={disabled || false}
        placeholder={placeholder}
        style={{ width: '100%', fontSize: 14 }}
        onChange={onChange}
        mode={mode || "none"}
    >
        {options.map((item, index) => isObject(item) ? <Option key={index} value={item.value}>{item.label}</Option> : <Option value={item}>{item}</Option>)}
    </Select>
}

export default CustomSelect;