import React from 'react';
import { Steps } from 'antd';
import { isPreviousStepTrue } from '../../../utils/formatSteps';

const { Step } = Steps;

const CustomStepper = ({ currentStep, steps, handleChange, employer, setCurrentStep }) => {
    return (
        <Steps progressDot current={currentStep} onChange={handleChange}>
            {steps.map((item, index) => {
                const isCurrentOrPreviousFinished =
                    currentStep >= index &&
                    Array.from({ length: index + 1 }, (_, i) => isPreviousStepTrue(steps[i].key, employer.steps))
                        .every(Boolean);

                const stepStatus =
                    currentStep === index
                        ? "process"
                        : isCurrentOrPreviousFinished
                            ? "finish"
                            : isPreviousStepTrue(item.key, employer.steps)
                                ? "process"
                                : undefined;

                return (
                    <Step

                        key={index}
                        title={item.title}
                        status={stepStatus}
                        onClick={() => handleStepClick(index, stepStatus, setCurrentStep)}
                        style={{ cursor: isClickable(stepStatus) ? 'pointer' : 'default' }}

                    />
                );
            })}
        </Steps>
    );
};

const handleStepClick = (index, stepStatus, setCurrentStep) => {
    if (isClickable(stepStatus)) {
        setCurrentStep(index);
    }
};

const isClickable = (stepStatus) => {
    return stepStatus === "process" || stepStatus === "finish";
};

export default CustomStepper;
