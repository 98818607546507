
import { call, put, takeLatest } from 'redux-saga/effects';
import {

    GET_PAYPERIOD,
    GET_PAYPERIOD_LOADING,
    GET_PAYPERIOD_SUCCESS,
    GET_PAYPERIOD_ERROR,

    CREATE_PAYPERIOD,
    CREATE_PAYPERIOD_LOADING,
    CREATE_PAYPERIOD_SUCCESS,
    CREATE_PAYPERIOD_ERROR,

    DELETE_PAYPERIOD,
    DELETE_PAYPERIOD_LOADING,
    DELETE_PAYPERIOD_SUCCESS,
    DELETE_PAYPERIOD_ERROR,

} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';



function* getPayPeriod({ body }) {
    yield put({ type: GET_PAYPERIOD_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.payPeriod.get_payperiods({ employerId: body.employerId }),
            // body
        });

        yield put({ type: GET_PAYPERIOD_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PAYPERIOD_ERROR, errorMessage: '' });
    }
}

function* createPayPeriod({ body }) {
    yield put({ type: CREATE_PAYPERIOD_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.payPeriod.create_payperiod({ employerId: body.employerId }),
            body: {
                name: body.name,
                from: body.from,
                to: body.to,
                lockingDate: body.lockingDate
            },
        });
        yield put({ type: CREATE_PAYPERIOD_SUCCESS, data: data });
        yield put({ type: GET_PAYPERIOD, body: { employerId: body.employerId } });
    } catch (error) {
        errorhandling(error)
        yield put({ type: CREATE_PAYPERIOD_ERROR, errorMessage: '' });
    }
}

function* deletePayPeriod({ body }) {
    yield put({ type: DELETE_PAYPERIOD_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'DELETE',
            url: apiConfig.payPeriod.delete_payperiod({ employerId: body.employerId, payPeriodId: body.id }),
            body
        });
        // yield new Promise(resolve => setTimeout(resolve, 2000));
        // const data = {}
        yield put({ type: DELETE_PAYPERIOD_SUCCESS, data: data });
        yield put({ type: GET_PAYPERIOD, body: { employerId: body.employerId } });
    } catch (error) {
        errorhandling(error)
        yield put({ type: DELETE_PAYPERIOD_ERROR, errorMessage: '' });
    }
}




/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPromotionsSaga() {
    yield takeLatest(GET_PAYPERIOD, getPayPeriod)
    yield takeLatest(CREATE_PAYPERIOD, createPayPeriod)
    yield takeLatest(DELETE_PAYPERIOD, deletePayPeriod)
}