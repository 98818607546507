import { call, put, takeLatest } from 'redux-saga/effects';
import {

    VERIFY_TOKEN_LOADING,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    VERIFY_TOKEN,

    REGISTER_EMPLOYER,
    REGISTER_EMPLOYER_START,
    REGISTER_EMPLOYER_SUCCESS,
    REGISTER_EMPLOYER_ERROR,
    PRE_VERIFY_TOKEN,
    PRE_VERIFY_TOKEN_SUCCESS,
    PRE_VERIFY_TOKEN_ERROR,
    PRE_VERIFY_TOKEN_LOADING
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { message } from 'antd';
import { apiConfig } from '../../utils/apiConfig';





function* verifyToken({ body }) {
    yield put({ type: VERIFY_TOKEN_LOADING });

    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.invitation.verify_token(),
            body: {
                token: body.token
            },
            headers: {
                Authorization: JSON.stringify({
                    role: "ADMIN",
                    user_id: "1234",
                    groups: ["ADVANCIA_ADMIN"]
                })
            }
        });

        // console.log({ data })
        // await 3 seconds
        // yield new Promise(resolve => setTimeout(resolve, 2000));

        // const data = {
        //     email: "example@example.com",
        //     employerID: "1234567890",
        //     employerName: "Egaliti",
        //     last2DigitsPhone: "10",
        // }

        yield put({ type: VERIFY_TOKEN_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: VERIFY_TOKEN_ERROR, errorMessage: 'Token Expired' });
    }
}

function* preVerifyTone({ body }) {
    yield put({
        type: PRE_VERIFY_TOKEN_LOADING,
    });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.invitation.pre_verify_token(),
            body: {
                token: body.token
            },
            headers: {
                Authorization: JSON.stringify({
                    role: "ADMIN",
                    user_id: "1234",
                    groups: ["ADVANCIA_ADMIN"]
                })
            }
        });

        // console.log({ data })
        // await 3 seconds
        // yield new Promise(resolve => setTimeout(resolve, 4000));

        // const data = {
        //     validToken: true
        // }

        yield put({ type: PRE_VERIFY_TOKEN_SUCCESS, data: data });
    }
    catch (error) {
        errorhandling(error)
        yield put({ type: PRE_VERIFY_TOKEN_ERROR, errorMessage: 'Token Expired' });
    }
}



function* registerEmployer({ body }) {
    yield put({ type: REGISTER_EMPLOYER_START });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: apiConfig.employers.register_employer(),
            body,
            headers: {
                Authorization: JSON.stringify({
                    role: "ADMIN",
                    user_id: "1234",
                    groups: ["ADVANCIA_ADMIN"]
                })
            }
        });

        yield put({ type: REGISTER_EMPLOYER_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: REGISTER_EMPLOYER_ERROR, errorMessage: error.message });
    }
}





/**
 * Root saga manages watcher lifecycle
 */
export default function* watchInviteSaga() {
    yield takeLatest(VERIFY_TOKEN, verifyToken)
    yield takeLatest(REGISTER_EMPLOYER, registerEmployer)
    yield takeLatest(PRE_VERIFY_TOKEN, preVerifyTone)
}