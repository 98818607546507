
import { call, put, takeLatest } from 'redux-saga/effects';
import {

    GET_INVOICE_BY_ID,
    GET_INVOICE_BY_ID_LOADING,
    GET_INVOICE_BY_ID_SUCCESS,
    GET_INVOICE_BY_ID_ERROR,

    SAVE_AND_APPROVE_INVOICE,
    SAVE_AND_APPROVE_INVOICE_LOADING,
    SAVE_AND_APPROVE_INVOICE_SUCCESS,
    SAVE_AND_APPROVE_INVOICE_ERROR,

    GET_INVOICE_ITEMS,
    GET_INVOICE_ITEMS_LOADING,
    GET_INVOICE_ITEMS_SUCCESS,
    GET_INVOICE_ITEMS_ERROR,

    GET_EMPLOYER_PAYPERIOD,
    GET_EMPLOYER_PAYPERIOD_LOADING,
    GET_EMPLOYER_PAYPERIOD_SUCCESS,
    GET_EMPLOYER_PAYPERIOD_ERROR
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';


function* getInvoiceById({ body }) {
    yield put({ type: GET_INVOICE_BY_ID_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/invoice/{invoiceId}',
            body
        });
        yield put({ type: GET_INVOICE_BY_ID_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_INVOICE_BY_ID_ERROR, errorMessage: '' });
    }
}

function* saveAndApproveInvoice({ body }) {
    yield put({ type: SAVE_AND_APPROVE_INVOICE_LOADING });
    try {
        yield call(api, {
            method: 'POST',
            url: 'salaryadvance/salary-manager/invoice',
            body
        });
        yield put({ type: SAVE_AND_APPROVE_INVOICE_SUCCESS, data: true });
    } catch (error) {
        errorhandling(error)
        yield put({ type: SAVE_AND_APPROVE_INVOICE_ERROR, errorMessage: '' });
    }
}

function* getInvoiceItems({ body }) {
    yield put({ type: GET_INVOICE_ITEMS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/invoice',
            body
        });
        yield put({ type: GET_INVOICE_ITEMS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_INVOICE_ITEMS_ERROR, errorMessage: '' });
    }
}

function* getEmployerPayPeriod({ body }) {
    yield put({ type: GET_EMPLOYER_PAYPERIOD_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/employerPayPeriods/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_PAYPERIOD_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_PAYPERIOD_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchInvoiceDetailsSaga() {
    yield takeLatest(SAVE_AND_APPROVE_INVOICE, saveAndApproveInvoice)
    yield takeLatest(GET_INVOICE_BY_ID, getInvoiceById)
    yield takeLatest(GET_INVOICE_ITEMS, getInvoiceItems)
    yield takeLatest(GET_EMPLOYER_PAYPERIOD, getEmployerPayPeriod)
}