import { combineReducers } from 'redux';
import {
    GET_ADVANCE_BY_ID_LOADING,
    GET_ADVANCE_BY_ID_SUCCESS,
    GET_ADVANCE_BY_ID_ERROR
} from './actions'

const initialState = {
    loading: false, data: {}, error: ""
}
const advance = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case GET_ADVANCE_BY_ID_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_ADVANCE_BY_ID_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_ADVANCE_BY_ID_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};





export default combineReducers({
    advance,
});