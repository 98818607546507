export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_LOADING = 'UPDATE_PASSWORD_LOADING'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const changePassword = (body) => ({ type: CHANGE_PASSWORD, body })
export const resetPassword = (body) => ({ type: RESET_PASSWORD, body })
export const updatePassword = (body) => ({ type: UPDATE_PASSWORD, body })