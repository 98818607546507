import { combineReducers } from 'redux';
import {

    GET_PROMOTION_BY_ID_LOADING,
    GET_PROMOTION_BY_ID_SUCCESS,
    GET_PROMOTION_BY_ID_ERROR,

    GET_ALL_PROMOTION_CODES_LOADING,
    GET_ALL_PROMOTION_CODES_SUCCESS,
    GET_ALL_PROMOTION_CODES_ERROR,

    UPSERT_EMPLOYER_PROMOTION_LOADING,
    UPSERT_EMPLOYER_PROMOTION_SUCCESS,
    UPSERT_EMPLOYER_PROMOTION_ERROR,

    UPDATE_PROMOTION_STATUS_LOADING,
    UPDATE_PROMOTION_STATUS_SUCCESS,
    UPDATE_PROMOTION_STATUS_ERROR,

} from './actions'

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const initialStateList = {
    loading: false, list: [], error: ""
}

const promotion = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_PROMOTION_BY_ID_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_PROMOTION_BY_ID_SUCCESS: {
            const data = action.data
            if (data.discountJsonConfig) {
                let discountJsonConfig = JSON.parse(data.discountJsonConfig)
                if (discountJsonConfig.ruleFieldMetas) {
                    data.discount = {}
                    data.limitation = {}
                    discountJsonConfig.ruleFieldMetas.forEach(element => {
                        if (element.fieldName === "ACCESSFEE") {
                            data.discount.accessFee = element.srcFldValuePercentage
                            data.limitation.amount = element.restriction
                        }
                        if (element.fieldName === "SURCHARGEFEE") {
                            data.discount.surchargeFee = element.srcFldValuePercentage
                        }
                    });
                }
            }
            return {
                loading: false,
                data: data,
                error: ""
            }
        }
        case GET_PROMOTION_BY_ID_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const promotionCodes = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_ALL_PROMOTION_CODES_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_ALL_PROMOTION_CODES_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_ALL_PROMOTION_CODES_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const promotionUpdates = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case UPDATE_PROMOTION_STATUS_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case UPDATE_PROMOTION_STATUS_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case UPDATE_PROMOTION_STATUS_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const upsertPromotion = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case UPSERT_EMPLOYER_PROMOTION_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case UPSERT_EMPLOYER_PROMOTION_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case UPSERT_EMPLOYER_PROMOTION_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    promotion,
    promotionCodes,
    upsertPromotion,
    promotionUpdates,
});