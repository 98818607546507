import { combineReducers } from 'redux';
import {
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
} from './actions'

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const forgotPasswordTokens = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case FORGOT_PASSWORD_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case FORGOT_PASSWORD_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case FORGOT_PASSWORD_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    forgotPasswordTokens
});