import { combineReducers } from 'redux';
import {
    GET_PAYPERIOD_LOADING,
    GET_PAYPERIOD_SUCCESS,
    GET_PAYPERIOD_ERROR,
    CREATE_PAYPERIOD_SUCCESS,
    CREATE_PAYPERIOD_LOADING,
    CREATE_PAYPERIOD_ERROR,

    DELETE_PAYPERIOD_LOADING,
    DELETE_PAYPERIOD_ERROR,
    DELETE_PAYPERIOD_SUCCESS
} from './actions'

const initialStateList = {
    loading: false, data: [], error: "", actionLoading: false
}


const payPeriods = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_PAYPERIOD_LOADING: {
            return {
                loading: true,
                data: [],
                error: "",
                actionLoading: false

            }
        }
        case GET_PAYPERIOD_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: "",
                actionLoading: false
            }
        }
        case GET_PAYPERIOD_ERROR: {
            return {
                loading: false,
                data: [],
                error: action.errorMessage,
                actionLoading: false
            }
        }
        case CREATE_PAYPERIOD_LOADING: {
            return {
                ...state,
                actionLoading: true
            }
        }
        case CREATE_PAYPERIOD_ERROR: {
            return {
                ...state,
                error: action.errorMessage,
                loading: false,
                actionLoading: false
            }
        }
        case DELETE_PAYPERIOD_LOADING: {
            return {
                ...state,
                actionLoading: true
            }
        }

        case DELETE_PAYPERIOD_ERROR: {
            return {
                ...state,
                error: action.errorMessage,
                loading: false,
                actionLoading: false
            }
        }
        case DELETE_PAYPERIOD_SUCCESS: {
            return {
                ...state,
                actionLoading: false
            }
        }
        default:
            return state
    }
};







export default combineReducers({
    payPeriods
});