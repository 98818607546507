import React from 'react'
import { Layout, Row, Col, Dropdown, Menu, Typography } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import '../../style.css'
import { CustomDropdown } from '../..';

const { Header } = Layout;
const { Text } = Typography

const LoggedInUserInfo = ({ handleMenuClick, userDetails, handleLogOut, employers }) => {
    let username = userDetails?.email?.split('@')[0];
    const employerId = userDetails["cognito:employerId"]
    const employer = employers?.find(item => item?.id === employerId)



    return (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="">
                <Row>
                    <Col span={12}>
                        <Text>{employer ? employer?.contactPerson?.name : username}</Text>
                    </Col>
                </Row>
            </Menu.Item>
            <Menu.Item key="change-password">
                Change Password
            </Menu.Item>
            <Menu.Item onClick={handleLogOut} key="logout" icon={<LogoutOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    );
}


const CustomHeader = ({ isSidebarCollapsed, toggleSidebar, employers, selectedEmployer, setSelectedEmployer,
    isEmployerDropdownVisible, handleMenuClick, userDetails, handleLogOut, path }) => {
    const disabledPaths = ['/', '/superadmin-dashboard', '/employer-details/new'];

    function isPathDisabled(path) {
        // Check if the path is in the disabledPaths array or if it matches the pattern '/employer-details/{dynamicID}'
        return disabledPaths.includes(path) || /^\/employer-details\/[a-f\d]{24}$/.test(path);
    }

    return (
        <Header className="container">
            <Row className="trigger">
                <Col span={1} onClick={() => toggleSidebar(!isSidebarCollapsed)}>
                    {isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Col>
                <Col span={isEmployerDropdownVisible ? 17 : 21}></Col>
                {isEmployerDropdownVisible ?
                    <Col span={4}>
                        <CustomDropdown
                            value={selectedEmployer.value}
                            disabled={isPathDisabled(path)}
                            onChange={(value) => value ? setSelectedEmployer(employers.list.find(item => item.id === value)) : setSelectedEmployer({ companyName: "All Employers", id: "" })}
                            options={[{ companyDetails: { name: "All Employers" }, id: "" }].concat(employers.list ? employers.list : []).map(item => ({ label: item?.companyDetails?.name, value: item.id }))}
                            placeholder={"Choose employer"}
                        />
                    </Col>
                    : null}
                <Col span={2} style={{ textAlign: "center" }} >
                    <Dropdown
                        overlay={
                            <LoggedInUserInfo
                                handleMenuClick={handleMenuClick}
                                userDetails={userDetails}
                                handleLogOut={handleLogOut}
                                employers={employers?.list || []}
                            />} trigger={['click']}>
                        <UserOutlined onClick={handleMenuClick} />
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    )
}

export default CustomHeader