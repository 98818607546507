import React from 'react'
import { Switch } from 'antd';

const CustomSwitch = ({ size, value, disabled, onChange }) => {
    return (
        <Switch
            size={size || "small"}
            checked={value}
            disabled={disabled || false}
            onChange={onChange}
        />
    );
}

export default CustomSwitch