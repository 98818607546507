import React from "react";
import { Input } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";

const CustomInputPassword = ({ placeholder, onChange, value, id, defaultValue }) => (
	<Input.Password
		size={"large"}
		id={id}
		autoComplete={"off"}
		value={value}
		placeholder={placeholder}
		defaultValue={defaultValue}
		onChange={e => onChange(e.target.value)}
		iconRender={visible => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
	/>
);

export default CustomInputPassword;
