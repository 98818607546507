import React from "react";
import { connect } from "react-redux";
import { PublicComponent } from "../components";

class Public extends React.Component {
  render() {
    return <PublicComponent />;
  }
}

const mapStateToProps = ({
  router: {
    location: { pathname },
  },
}) => ({
  pathname,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Public);
