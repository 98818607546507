export const env = "dev";

// const baseURL = "https://4du613edx5.execute-api.eu-west-2.amazonaws.com/sa/"
const baseURL = "https://dev.api.egaliti.com/"
const egalitiBaseURL = "https://dev.api.egaliti.com/"
// const baseURL = "https://adv.egaliti.com/"

export const dev = {
    baseURL: `${baseURL}`, // 'https://services.demo.paytime.com.au/'
    s3BucketPath: "https://salaryadvance-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-dev",
    ADMIN_KEY: "egaliti_client_key_dev",
    egalitiBaseURL: `${egalitiBaseURL}`
}

export const stage = {
    baseURL: `${baseURL}`, // 'https://services.stage.paytime.com.au/'
    s3BucketPath: "https:/salaryadvance-stage-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-stage",
    ADMIN_KEY: "xxxxxx",
    egalitiBaseURL: `${egalitiBaseURL}`
}

export const qat = {
    baseURL: `${baseURL}`, // 'https://services.qat.paytime.com.au/'
    s3BucketPath: "https://salaryadvance-qat-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-qat",
    ADMIN_KEY: "xxxxxx",
    egalitiBaseURL: `${egalitiBaseURL}`
}

export const prod = {
    baseURL: `${baseURL}`, // 'https://services.prod.paytime.com.au/'
    s3BucketPath: "https://salaryadvance-prod-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-prod",
    ADMIN_KEY: "xxxxxx",
    egalitiBaseURL: `${egalitiBaseURL}`
}
