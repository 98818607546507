import React from 'react'
import { Image } from 'antd';

const CustomImage = ({ preview, width, height, src }) => (
    <Image
        width={width}
        height={height}
        src={src}
        preview={preview || false}
        
    />
);

export default CustomImage;