import { combineReducers } from 'redux';
import {

    GET_CREDENTIALS_LOADING,
    GET_CREDENTIALS_SUCCESS,
    GET_CREDENTIALS_ERROR,

    GET_FILE_PROCESS_STATUS_LOADING,
    GET_FILE_PROCESS_STATUS_SUCCESS,
    GET_FILE_PROCESS_STATUS_ERROR,

} from './actions'

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const initialStateList = {
    loading: false, data: [], error: ""
}

const credentials = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_CREDENTIALS_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_CREDENTIALS_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_CREDENTIALS_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const history = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_FILE_PROCESS_STATUS_LOADING: {
            return {
                loading: true,
                data: [],
                error: ""
            }
        }
        case GET_FILE_PROCESS_STATUS_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_FILE_PROCESS_STATUS_ERROR: {
            return {
                loading: false,
                data: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    history,
    credentials
});