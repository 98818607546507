import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_LOADING,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTION_TYPES,
  GET_TRANSACTION_TYPES_LOADING,
  GET_TRANSACTION_TYPES_SUCCESS,
  GET_TRANSACTION_TYPES_ERROR,
  GET_EXPORT_TRANSACTIONS_ERROR,
  GET_EXPORT_TRANSACTIONS_SUCCESS,
  GET_EXPORT_TRANSACTIONS_LOADING,
  GET_EXPORT_TRANSACTIONS,
} from "./actions";
import { api } from "../../utils/api";
import { errorhandling } from "../../utils/helper";
import { apiConfig } from "../../utils/apiConfig";


function* getTransactions({ body }) {
  yield put({ type: GET_TRANSACTIONS_LOADING });
  try {
    const query = {
      employee_id: body.employeeId,
      employer_id: body.employerId,
      page_number: body.page || 1,
      // search: body.search || '',
      item_per_page: 10
    }
    const { data } = yield call(api, {
      method: "GET",
      url: apiConfig.payroll.get_advances({ query }),
      // body,
    });

    yield put({ type: GET_TRANSACTIONS_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_TRANSACTIONS_ERROR, errorMessage: "" });
  }
}

function* getExportTransactions({ body }) {
  yield put({ type: GET_EXPORT_TRANSACTIONS_LOADING });
  try {
    // const { data } = yield call(api, {
    //   method: "GET",
    //   url: baseURL + "salaryadvance/salary-manager/transactions/export",
    //   body,
    // });
    const data = {
      response: [
        {
          id: 1,
          employeeId: 1,
          employeeName: "John Doe",
          transactionType: "Salary Advance",
          transactionAmount: 1000,
          transactionTime: "2021-09-01T00:00:00.000Z",
          transactionStatus: "Approved",
          transactionId: "1",
          transactionDescription: "Salary Advance",
        },
        {
          id: 2,
          employeeId: 2,
          employeeName: "Jane Doe",
          transactionType: "Salary Advance",
          transactionAmount: 1000,
          transactionTime: "2021-09-01T00:00:00.000Z",
          transactionStatus: "Approved",
          transactionId: "2",
          transactionDescription: "Salary Advance",
        },
      ],
    };
    yield put({
      type: GET_EXPORT_TRANSACTIONS_SUCCESS,
      data: data.response,
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EXPORT_TRANSACTIONS_ERROR, errorMessage: "" });
  }
}

function* getTransactionTypes({ body }) {
  yield put({ type: GET_TRANSACTION_TYPES_LOADING });
  try {
    // const { data } = yield call(api, {
    //   method: "GET",
    //   url: baseURL + "salaryadvance/salary-manager/transactionType",
    //   body,
    // });
    const data = [
    ]

    yield put({ type: GET_TRANSACTION_TYPES_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_TRANSACTION_TYPES_ERROR, errorMessage: "" });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchTransactionsSaga() {
  yield takeLatest(GET_TRANSACTIONS, getTransactions);
  yield takeLatest(GET_EXPORT_TRANSACTIONS, getExportTransactions);
  yield takeLatest(GET_TRANSACTION_TYPES, getTransactionTypes);
}
