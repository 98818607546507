import { combineReducers } from 'redux';
import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,

    INVITE_LOADING,
    INVITE_SUCCESS,
    REFRESH_INVITE,
    INVITE_ERROR,
    
    SAVE_USER_DETAILS
} from './actions'

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const tokens = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case LOGIN_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case LOGIN_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case LOGIN_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const inviteTokens = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case INVITE_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case INVITE_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case INVITE_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        case REFRESH_INVITE: {
            return initialStateObject
        }
        default:
            return state
    }
};

const userDetails = ( state = {}, action) => {
    switch (action.type) {
        case SAVE_USER_DETAILS: {
            return action.data
        }
        default:
            return state
    }
};

export default combineReducers({
    tokens,
    userDetails,
    inviteTokens,
});