import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const CustomTextArea = ({ placeholder, onChange, value, rows, disabled }) => (
    <TextArea
        rows={rows || 3}
        value={value}
        disabled={disabled || false}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
    />
);

export default CustomTextArea;
