import { combineReducers } from 'redux';
import {
    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_LOADING,
    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_SUCCESS,
    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_ERROR,

    GET_EMPLOYER_DASHBOARD_TOTAL_USERS_LOADING,
    GET_EMPLOYER_DASHBOARD_TOTAL_USERS_SUCCESS,
    GET_EMPLOYER_DASHBOARD_TOTAL_USERS_ERROR,

    GET_EMPLOYER_DASHBOARD_APP_USAGE_LOADING,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_SUCCESS,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_ERROR,

    GET_EMPLOYER_DASHBOARD_TOP_USERS_LOADING,
    GET_EMPLOYER_DASHBOARD_TOP_USERS_SUCCESS,
    GET_EMPLOYER_DASHBOARD_TOP_USERS_ERROR,

    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_LOADING,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_SUCCESS,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_ERROR
} from './actions'

const initialState = {
    loading: false, data: {}, error: ""
}

const initialStateList = {
    loading: false, list: [], error: ""
}

const totalWithdrawals = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const totalUsers = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case GET_EMPLOYER_DASHBOARD_TOTAL_USERS_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_TOTAL_USERS_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_TOTAL_USERS_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const appUsage = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case GET_EMPLOYER_DASHBOARD_APP_USAGE_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_APP_USAGE_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_APP_USAGE_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const topUsers = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_EMPLOYER_DASHBOARD_TOP_USERS_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_TOP_USERS_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_TOP_USERS_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const appUsuageUsers = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    totalWithdrawals,
    appUsuageUsers,
    totalUsers,
    topUsers,
    appUsage
});