export const GET_PAY_CYCLES = "GET_PAY_CYCLES"
export const GET_PAY_CYCLES_LOADING = "GET_PAY_CYCLES_LOADING"
export const GET_PAY_CYCLES_SUCCESS = "GET_PAY_CYCLES_SUCCESS"
export const GET_PAY_CYCLES_ERROR = "GET_PAY_CYCLES_ERROR"

export const CHANGE_PAYCYCLE_STATUS = "CHANGE_PAYCYCLE_STATUS"
export const CHANGE_PAYCYCLE_STATUS_LOADING = "CHANGE_PAYCYCLE_STATUS_LOADING"
export const CHANGE_PAYCYCLE_STATUS_SUCCESS = "CHANGE_PAYCYCLE_STATUS_SUCCESS"
export const CHANGE_PAYCYCLE_STATUS_ERROR = "CHANGE_PAYCYCLE_STATUS_ERROR"


export const getPayCycles = (body) => ({ type: GET_PAY_CYCLES, body })
export const changePayCyclesStatus = (body) => ({ type: CHANGE_PAYCYCLE_STATUS, body })
