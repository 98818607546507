  
import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment'

const CustomDatePicker = ({ onChange, placeholder, value, disabledDate = false, disabled }) => {
    return (
        <DatePicker
            disabledDate={disabledDate}
            format={['DD/MM/YYYY']}
            value={value ? moment(value) : ""}
            placeholder={placeholder}
            size={"large"}
            disabled={disabled || false}
            style={{ width: '100%', height: 40 }}
            onChange={(date) => onChange(date)}
        />
    )
}


export default CustomDatePicker;