import React from 'react'
import { Modal } from 'antd';

const CustomModal = ({ title, content, buttons, visible, closable, footer, width, handleClose }) => {
    return (
        <Modal
            title={title}
            width={width || 520}
            closable={closable || false}
            visible={visible}
            footer={footer || null}
            onCancel={() => handleClose ? handleClose() : {}}
        >
            {content}
            {buttons}
        </Modal>
    );
}


export default CustomModal