import { combineReducers } from 'redux';
import {

    GET_PAY_CYCLES_LOADING,
    GET_PAY_CYCLES_SUCCESS,
    GET_PAY_CYCLES_ERROR,

} from './actions'

const initialStateList = {
    loading: false, list: [], error: "" 
}

const payCycles = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_PAY_CYCLES_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_PAY_CYCLES_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_PAY_CYCLES_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    payCycles
});