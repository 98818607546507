export const GET_EMPLOYER_PAY_CYCLE_LIST = "GET_EMPLOYER_PAY_CYCLE_LIST"
export const GET_EMPLOYER_PAY_CYCLE_LIST_LOADING = "GET_EMPLOYER_PAY_CYCLE_LIST_LOADING"
export const GET_EMPLOYER_PAY_CYCLE_LIST_SUCCESS = "GET_EMPLOYER_PAY_CYCLE_LIST_SUCCESS"
export const GET_EMPLOYER_PAY_CYCLE_LIST_ERROR = "GET_EMPLOYER_PAY_CYCLE_LIST_ERROR"

export const GET_PAY_PERIOD_LIST = "GET_PAY_PERIOD_LIST"
export const GET_PAY_PERIOD_LIST_LOADING = "GET_PAY_PERIOD_LIST_LOADING"
export const GET_PAY_PERIOD_LIST_SUCCESS = "GET_PAY_PERIOD_LIST_SUCCESS"
export const GET_PAY_PERIOD_LIST_ERROR = "GET_PAY_PERIOD_LIST_ERROR"
export const RESET_PAY_PERIOD_LIST = "RESET_PAY_PERIOD_LIST"

export const REFRESH_PAY_PERIOD_LIST = "REFRESH_PAY_PERIOD_LIST"
export const REFRESH_PAY_PERIOD_LIST_LOADING = "REFRESH_PAY_PERIOD_LIST_LOADING"
export const REFRESH_PAY_PERIOD_LIST_SUCCESS = "REFRESH_PAY_PERIOD_LIST_SUCCESS"
export const REFRESH_PAY_PERIOD_LIST_ERROR = "REFRESH_PAY_PERIOD_LIST_ERROR"

export const START_PAY_PERIOD = "START_PAY_PERIOD"
export const START_PAY_PERIOD_LOADING = "START_PAY_PERIOD_LOADING"
export const START_PAY_PERIOD_SUCCESS = "START_PAY_PERIOD_SUCCESS"
export const START_PAY_PERIOD_ERROR = "START_PAY_PERIOD_ERROR"


export const getEmployerPayCycleList = (body) => ({ type: GET_EMPLOYER_PAY_CYCLE_LIST, body })
export const getPayPeriodList = (body) => ({ type: GET_PAY_PERIOD_LIST, body })
export const resetPayPeriodList = (body) => ({ type: RESET_PAY_PERIOD_LIST, body })
export const refreshPayPeriodList = (body) => ({ type: REFRESH_PAY_PERIOD_LIST, body })
export const startPayPeriod = (body) => ({ type: START_PAY_PERIOD, body })