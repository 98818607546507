
import { call, put, takeLatest } from 'redux-saga/effects';
import {

    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL,
    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_LOADING,
    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_SUCCESS,
    GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_ERROR,

    GET_EMPLOYER_DASHBOARD_TOTAL_USERS,
    GET_EMPLOYER_DASHBOARD_TOTAL_USERS_LOADING,
    GET_EMPLOYER_DASHBOARD_TOTAL_USERS_SUCCESS,
    GET_EMPLOYER_DASHBOARD_TOTAL_USERS_ERROR,

    GET_EMPLOYER_DASHBOARD_APP_USAGE,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_LOADING,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_SUCCESS,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_ERROR,

    GET_EMPLOYER_DASHBOARD_TOP_USERS,
    GET_EMPLOYER_DASHBOARD_TOP_USERS_LOADING,
    GET_EMPLOYER_DASHBOARD_TOP_USERS_SUCCESS,
    GET_EMPLOYER_DASHBOARD_TOP_USERS_ERROR,

    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_LOADING,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_SUCCESS,
    GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_ERROR
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';


function* getTotalWithdrawals({ body }) {
    yield put({ type: GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/dashboard/totalWithdrawls/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL_ERROR, errorMessage: '' });
    }
}

function* getTotalUsers({ body }) {
    yield put({ type: GET_EMPLOYER_DASHBOARD_TOTAL_USERS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/dashboard/totalUsers/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_DASHBOARD_TOTAL_USERS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_DASHBOARD_TOTAL_USERS_ERROR, errorMessage: '' });
    }
}

function* getAppUsage({ body }) {
    yield put({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/dashboard/appUsage/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_ERROR, errorMessage: '' });
    }
}

function* getTopUsers({ body }) {
    yield put({ type: GET_EMPLOYER_DASHBOARD_TOP_USERS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/dashboard/topUsers/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_DASHBOARD_TOP_USERS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_DASHBOARD_TOP_USERS_ERROR, errorMessage: '' });
    }
}

function* getAppUsuageUsers({ body }) {
    yield put({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employees/usages/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerDashboardSaga() {
    yield takeLatest(GET_EMPLOYER_DASHBOARD_TOTAL_WITHDRAWAL, getTotalWithdrawals)
    yield takeLatest(GET_EMPLOYER_DASHBOARD_TOTAL_USERS, getTotalUsers)
    yield takeLatest(GET_EMPLOYER_DASHBOARD_APP_USAGE, getAppUsage)
    yield takeLatest(GET_EMPLOYER_DASHBOARD_TOP_USERS, getTopUsers)
    yield takeLatest(GET_EMPLOYER_DASHBOARD_APP_USAGE_USERS, getAppUsuageUsers)
}