import React from 'react';
import { Spin, Modal } from 'antd';

const Spinner = ({ loading }) => {
    return (
        <Modal visible={loading} closable={false} footer={null} centered={true}
            modalRender={
                () =>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin spinning={loading}/>
                    </div>
            }
        />

    )
}

export default Spinner