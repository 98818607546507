import { combineReducers } from 'redux';
import {
    SET_CURRENT_STEP,

    GET_EMPLOYER_BY_ID_LOADING,
    GET_EMPLOYER_BY_ID_SUCCESS,
    GET_EMPLOYER_BY_ID_ERROR,

    RESET_EMPLOYER,

    SET_COMPANY_DETAILS,
    RESET_COMPANY_DETAILS,

    SET_CONTACT_PERSON_DETAILS,
    RESET_CONTACT_PERSON_DETAILS,

    SET_AUTH_CONFIG,
    RESET_AUTH_CONFIG,

    SET_PAYMENT_CONFIG,
    RESET_PAYMENT_CONFIG,

    SET_ACCESS_FEE_CONFIG,
    RESET_ACCESS_FEE_CONFIG,

    SET_ACCESS_LIMIT,
    RESET_ACCESS_LIMIT,

    SET_DIRECT_DEBIT_CARD_CONFIG,
    RESET_DIRECT_DEBIT_CARD_CONFIG,

    SET_SUBSCRIPTIONS,
    RESET_SUBSCRIPTIONS,

    UPDATE_EMPLOYER_LOADING,
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_ERROR,

    ADD_EMPLOYER_LOADING,
    ADD_EMPLOYER_SUCCESS,
    ADD_EMPLOYER_ERROR,

    ADD_EMPLOYER_V2_LOADING,
    ADD_EMPLOYER_V2_SUCCESS,
    ADD_EMPLOYER_V2_ERROR,

    GET_PRESIGNED_URL_LOADING,
    GET_PRESIGNED_URL_SUCCESS,
    GET_PRESIGNED_URL_ERROR,

    READ_UPLOADED_FILE_LOADING,
    READ_UPLOADED_LEGAL_FILE_SUCCESS,
    READ_UPLOADED_PAYROLL_FILE_SUCCESS,
    READ_UPLOADED_SAAS_FILE_SUCCESS,
    READ_UPLOADED_FILE_ERROR,

    // Business
    GET_BUSINESSES_LOADING,
    GET_BUSINESSES_SUCCESS,
    GET_BUSINESSES_ERROR

} from './actions'

const currentStep = (state, action) => {
    if (typeof state === 'undefined') {
        return -1
    }
    switch (action.type) {
        case SET_CURRENT_STEP: {
            return action.data
        }
        default:
            return state
    }
};

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const employer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case UPDATE_EMPLOYER_LOADING: {
            return {
                loading: true,
                data: state.data,
                error: ""
            }
        }

        case UPDATE_EMPLOYER_LOADING:
        case ADD_EMPLOYER_LOADING:
        case ADD_EMPLOYER_V2_LOADING:
        case GET_EMPLOYER_BY_ID_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case UPDATE_EMPLOYER_SUCCESS:
        case ADD_EMPLOYER_SUCCESS:
        case ADD_EMPLOYER_V2_SUCCESS:
        case GET_EMPLOYER_BY_ID_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case UPDATE_EMPLOYER_ERROR:
        case ADD_EMPLOYER_ERROR:
        case ADD_EMPLOYER_V2_ERROR:
        case GET_EMPLOYER_BY_ID_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        case RESET_EMPLOYER: {
            return {
                loading: false,
                data: {},
                error: ""
            }
        }
        default:
            return state
    }
};

const companyDetails = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_COMPANY_DETAILS: {
            return { ...action.data, ...action.data.companyDetails } || {}
        }
        case RESET_COMPANY_DETAILS: {
            return {}
        }
        default:
            return state
    }
};

const contactPersonDetails = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {

        case SET_CONTACT_PERSON_DETAILS: {
            return action.data || {}
        }
        case RESET_CONTACT_PERSON_DETAILS: {
            return {}
        }
        default:
            return state
    }
};

const paymentConfig = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_PAYMENT_CONFIG: {
            return action.data || {}
        }
        case RESET_PAYMENT_CONFIG: {
            return {}
        }
        default:
            return state
    }
};

const payrollAuthConfig = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_AUTH_CONFIG: {
            return action.data || {}
        }
        case RESET_AUTH_CONFIG: {
            return {}
        }
        default:
            return state
    }
};

const accessFeeConfigs = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_ACCESS_FEE_CONFIG: {
            return action.data || {}
        }
        case RESET_ACCESS_FEE_CONFIG: {
            return {}
        }
        default:
            return state
    }
};

const accessLimits = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_ACCESS_LIMIT: {
            return action.data || {}
        }
        case RESET_ACCESS_LIMIT: {
            return {}
        }
        default:
            return state
    }
};

const subscription = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_SUBSCRIPTIONS: {
            return action.data || {}
        }
        case RESET_SUBSCRIPTIONS: {
            return {}
        }
        default:
            return state
    }
};

const directDebitAccountConfig = (state, action) => {
    if (typeof state === 'undefined') {
        return {}
    }
    switch (action.type) {
        case SET_DIRECT_DEBIT_CARD_CONFIG: {
            return action.data || {}
        }
        case RESET_DIRECT_DEBIT_CARD_CONFIG: {
            return {}
        }
        default:
            return state
    }
};

const presignedURL = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_PRESIGNED_URL_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_PRESIGNED_URL_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_PRESIGNED_URL_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const uploadedLegalDocsFileUrl = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case READ_UPLOADED_FILE_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case READ_UPLOADED_LEGAL_FILE_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case READ_UPLOADED_FILE_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const uploadedPayrollBankDDAuthDocsFileUrl = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case READ_UPLOADED_FILE_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case READ_UPLOADED_PAYROLL_FILE_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case READ_UPLOADED_FILE_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const uploadedSaasBankDDAuthDocsFileUrl = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case READ_UPLOADED_FILE_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case READ_UPLOADED_SAAS_FILE_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case READ_UPLOADED_FILE_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};


const businesses = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_BUSINESSES_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_BUSINESSES_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_BUSINESSES_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
}

export default combineReducers({
    currentStep,
    employer,
    presignedURL,
    payrollAuthConfig,
    paymentConfig,
    companyDetails,
    contactPersonDetails,
    accessFeeConfigs,
    accessLimits,
    directDebitAccountConfig,
    subscription,
    uploadedLegalDocsFileUrl,
    uploadedPayrollBankDDAuthDocsFileUrl,
    uploadedSaasBankDDAuthDocsFileUrl,
    businesses
});