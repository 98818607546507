import { combineReducers } from 'redux';
import {
    GET_INVOICE_BY_ID_LOADING,
    GET_INVOICE_BY_ID_SUCCESS,
    GET_INVOICE_BY_ID_ERROR,

    GET_INVOICE_ITEMS_LOADING,
    GET_INVOICE_ITEMS_SUCCESS,
    GET_INVOICE_ITEMS_ERROR,

    GET_EMPLOYER_PAYPERIOD_LOADING,
    GET_EMPLOYER_PAYPERIOD_SUCCESS,
    GET_EMPLOYER_PAYPERIOD_ERROR,

    SAVE_AND_APPROVE_INVOICE_LOADING,
    SAVE_AND_APPROVE_INVOICE_SUCCESS,
    SAVE_AND_APPROVE_INVOICE_ERROR,
} from './actions'

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const initialStateList = {
    loading: false, list: [], error: ""
}

const invoice = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_INVOICE_BY_ID_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_INVOICE_BY_ID_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_INVOICE_BY_ID_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const invoiceItems = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_INVOICE_ITEMS_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_INVOICE_ITEMS_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_INVOICE_ITEMS_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const employerPeriods = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_EMPLOYER_PAYPERIOD_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_EMPLOYER_PAYPERIOD_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_EMPLOYER_PAYPERIOD_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const invoiceApproved = (state, action) => {
    if (typeof state === 'undefined') {
        return false
    }
    switch (action.type) {
        case SAVE_AND_APPROVE_INVOICE_LOADING: {
            return false
        }
        case SAVE_AND_APPROVE_INVOICE_SUCCESS: {
            return action.data
        }
        case SAVE_AND_APPROVE_INVOICE_ERROR: {
            return false
        }
        default:
            return state
    }
};

export default combineReducers({
    invoice,
    invoiceItems,
    employerPeriods,
    invoiceApproved
});