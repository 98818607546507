export const GET_EXTERNAL_PAYROLL_EMPLOYEES = "GET_EXTERNAL_PAYROLL_EMPLOYEES"
export const GET_EXTERNAL_PAYROLL_EMPLOYEES_LOADING = "GET_EXTERNAL_PAYROLL_EMPLOYEES_LOADING"
export const GET_EXTERNAL_PAYROLL_EMPLOYEES_SUCCESS = "GET_EXTERNAL_PAYROLL_EMPLOYEES_SUCCESS"
export const GET_EXTERNAL_PAYROLL_EMPLOYEES_ERROR = "GET_EXTERNAL_PAYROLL_EMPLOYEES_ERROR"

export const UNBLOCK_EMPLOYEES = "UNBLOCK_EMPLOYEES"
export const UNBLOCK_EMPLOYEES_LOADING = "UNBLOCK_EMPLOYEES_LOADING"
export const UNBLOCK_EMPLOYEES_SUCCESS = "UNBLOCK_EMPLOYEES_SUCCESS"
export const UNBLOCK_EMPLOYEES_ERROR = "UNBLOCK_EMPLOYEES_ERROR"

export const BLOCK_EMPLOYEES = "BLOCK_EMPLOYEES"
export const BLOCK_EMPLOYEES_LOADING = "BLOCK_EMPLOYEES_LOADING"
export const BLOCK_EMPLOYEES_SUCCESS = "BLOCK_EMPLOYEES_SUCCESS"
export const BLOCK_EMPLOYEES_ERROR = "BLOCK_EMPLOYEES_ERROR"

export const getExternalPayrollEmployees = (body) => ({ type: GET_EXTERNAL_PAYROLL_EMPLOYEES, body })
export const unblockEmployees = (body) => ({ type: UNBLOCK_EMPLOYEES, body })
export const blockEmployees = (body) => ({ type: BLOCK_EMPLOYEES, body })