import { combineReducers } from 'redux';
import {

    GET_INVOICES_LOADING,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_ERROR,

} from './actions'

const initialStateObject = {
    loading: false, data: {}, error: "" 
}

const invoices = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_INVOICES_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_INVOICES_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_INVOICES_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    invoices
});