import { combineReducers } from 'redux';
import {
    GET_EXTERNAL_PAYROLL_EMPLOYEES_LOADING,
    GET_EXTERNAL_PAYROLL_EMPLOYEES_SUCCESS,
    GET_EXTERNAL_PAYROLL_EMPLOYEES_ERROR,
} from './actions'

const initialState = {
    loading: false, data: {}, error: ""
}


const employees = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (action.type) {
        case GET_EXTERNAL_PAYROLL_EMPLOYEES_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_EXTERNAL_PAYROLL_EMPLOYEES_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_EXTERNAL_PAYROLL_EMPLOYEES_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    employees
});