export const GET_USERS = "GET_USERS"
export const GET_USERS_LOADING = "GET_USERS_LOADING"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_ERROR = "GET_USERS_ERROR"

export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const GET_USER_BY_ID_LOADING = "GET_USER_BY_ID_LOADING"
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS"
export const GET_USER_BY_ID_ERROR = "GET_USER_BY_ID_ERROR"

export const ADD_USER = "ADD_USER"
export const ADD_USER_LOADING = "ADD_USER_LOADING"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_ERROR = "ADD_USER_ERROR"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const ENABLE_DISABLE_USER = "ENABLE_DISABLE_USER"
export const ENABLE_DISABLE_USER_LOADING = "ENABLE_DISABLE_USER_LOADING"
export const ENABLE_DISABLE_USER_SUCCESS = "ENABLE_DISABLE_USER_SUCCESS"
export const ENABLE_DISABLE_USER_ERROR = "ENABLE_DISABLE_USER_ERROR"

// Invitations 
export const GET_INVITATION_USERS = "GET_INVITATION_USERS"
export const GET_INVITATION_USERS_LOADING = "GET_INVITATION_USERS_LOADING"
export const GET_INVITATION_USERS_SUCCESS = "GET_INVITATION_USERS_SUCCESS"
export const GET_INVITATION_USERS_ERROR = "GET_INVITATION_USERS_ERROR"

export const getUsers = (body) => ({ type: GET_USERS, body })
export const getInvitationUsers = (body) => ({ type: GET_INVITATION_USERS, body })
export const createUser = (body) => ({ type: ADD_USER, body })
export const getUserById = (body) => ({ type: GET_USER_BY_ID, body })
export const updateUserById = (body) => ({ type: UPDATE_USER, body })
export const updateStatus = (body) => ({ type: ENABLE_DISABLE_USER, body })